<template>
<!--             :modules="[
            SwiperAutoplay,
            SwiperNavigation,
            SwiperPagination,
            SwiperEffectFade,
        ]"-->
    <Swiper
        :slides-per-view="sliderProps.slidesPerView"
        :loop="sliderProps.loop"
        :speed="sliderProps.speed"
        :autoplay="sliderProps.autoplay"
        :space-between="sliderProps.spaceBetween"
        :centered-slides="sliderProps.centeredSlides"
        :pagination="sliderProps.pagination"
        :navigation="sliderProps.navigation"
        :effect="sliderProps.effect"
        :breakpoints="sliderProps.breakpoints"
        :free-mode="sliderProps.freeMode"
        @swiper="sliderProps.events?.onInit"
        @slideChange="sliderProps.events?.onSlideChange"
    >
        <SwiperSlide
            v-for="(slide, index) in newArray"
            :key="`slide-${index}`"
        >
            <slot name="slide" :slide="slide" />
        </SwiperSlide>

        <template v-if="sliderProps.navigation">
            <button class="swiper-button swiper-button-prev" aria-label="Промотать слайдер назад">
                <svg-icon
                    :class="`icon--${sliderProps.navigation.prevElIcon}`"
                    :name="sliderProps.navigation.prevElIcon"
                />
            </button>
            <button class="swiper-button swiper-button-next" aria-label="Промотать слайдер вперед">
                <svg-icon
                    :class="`icon--${sliderProps.navigation.nextElIcon}`"
                    :name="sliderProps.navigation.nextElIcon"
                />
            </button>
        </template>
    </Swiper>
</template>

<script setup lang="ts">
import type { ISliderProps } from '~/types';

const props = defineProps({
    sliderProps: { type: Object as PropType<ISliderProps>, required: true, readonly: true },
    sliderStyle: { type: String, readonly: true },
    array: { type: Array, default: [], required: true },
});

const newArray = computed(() => {
    if (props.sliderProps.loop) {
        return props.array.concat(props.array);
    } else {
        return props.array;
    }
});
</script>

<style lang="scss" src="~/assets/scss/components/ui/slider.scss"></style>
